<template>
	<div class="mhome">
		<div class="mhome-wrap">
			<div class="mhome-order">
				<div class="morder-status">
					<div class="status-main">
						{{ orderInfo.status | statusFilter($t('mobile.filters.orderStatus')) }}
					</div>
					<div class="status-tip" v-if="orderInfo.status === 0">{{$t('mobile.order.closeReason')+':'}}{{ orderInfo.cancel_info.remark }}</div>
				</div>
				<div class="morder-main">
					<div class="morder-head">
						<router-link v-if="orderInfo.seller_id" :to="{ name: 'mStoreInfo', params: { id: orderInfo.seller_id }}" class="name">{{orderInfo.seller_name}}<i class="el-icon-arrow-right"></i></router-link>
					</div>
					<div class="morder-goods">
						<div class="item" v-for="(goods,index) in orderInfo.order_goods" :key="index">
							<router-link class="cell" :to="{ name: 'mGoodsInfo', params: { id: goods.goods_id }}">
								<img class="cell-pic" :src="goods.goods_image" alt="">
								<div class="cell-main">
									<div class="name">{{goods.goods_name}}</div>
									<div class="desc">{{goods.specification}}</div>
								</div>
								<div class="cell-box">
									<div class="price">￥<span>{{goods.price}}</span></div>
									<div class="number">x{{goods.quantity}}</div>
								</div>
							</router-link>
						</div>
						<div class="actions" v-if="orderInfo.status > 11">
							<!-- 请退货，待商家处理中 -->
							<router-link v-if="[1,2,3].includes(orderInfo.refund_status)" :to="{ name: 'mRefundInfo', params:{ id: orderInfo.apply_refund_id }}" class="btn">{{ orderInfo.refund_status | statusFilter($t('mobile.filters.refundStatus'))}}</router-link>
							<router-link v-else :to="{ name: 'mOrderRefund', params:{ order_id: orderInfo.order_id }}" class="btn">{{orderInfo.status!==40?$t('mobile.actions.refund'):$t('mobile.actions.applyAfterSales')}}</router-link>
						</div>
					</div>
					<div class="morder-info">
						<div class="item">
							<div class="item-label">{{$t('mobile.order.totalPrice')}}</div>
							<div class="item-block">{{$t('mobile.order.totalPrice')}}<span class="price">￥<em>{{orderInfo.goods_amount || '0.00'}}</em></span></div>
						</div>
						<div class="item">
							<div class="item-label">{{$t('mobile.order.freight')}}</div>
							<div class="item-block">{{$t('mobile.order.freight')}}({{$t('mobile.order.express')}})<span class="price" v-if="addressInfo">￥<em>{{addressInfo.shipping_fee || '0.00'}}</em></span></div>
						</div>
						<div class="item" v-if="orderInfo.coupon_id>0">
							<div class="item-label">{{$t('mobile.order.shopOffres')}}</div>
							<div class="item-block">{{$t('mobile.order.shopOffres')}}<span class="price red">-￥<em>{{orderInfo.discount || '0.00'}}</em></span></div>
						</div>
						<div class="item">
							<div class="item-label">{{$t('mobile.order.actualPay')}}</div>
							<div class="item-block"><span class="price total">￥<em>{{orderInfo.order_amount || '0.00'}}</em></span></div>
						</div>
						<div class="line"></div>
						<div class="item">
							<div class="item-label">{{$t('mobile.order.receiveInfo')}}</div>
							<div class="item-block" v-if="addressInfo">{{addressInfo.consignee}}，{{addressInfo.phone_tel || addressInfo.phone_mob}}，{{addressInfo.region_name+addressInfo.address}}</div>
						</div>
						<div class="item">
							<div class="item-label">{{$t('mobile.order.number')}}</div>
							<div class="item-block">{{orderInfo.order_sn}}</div>
						</div>
						<template v-if="orderInfo.status > 11">
							<div class="item">
								<div class="item-label">{{$t('mobile.order.payType')}}</div>
								<div class="item-block">{{orderInfo.payment_name}}</div>
							</div>
							<div class="item">
								<div class="item-label">{{$t('mobile.order.payNumber')}}</div>
								<div class="item-block">{{orderInfo.out_trade_sn}}</div>
							</div>
						</template>
						<div class="item">
							<div class="item-label">{{$t('mobile.order.createTime')}}</div>
							<div class="item-block">{{orderInfo.add_time | parseTime()}}</div>
						</div>
						<div class="item" v-if="orderInfo.status > 11">
							<div class="item-label">{{$t('mobile.order.payTime')}}</div>
							<div class="item-block">{{orderInfo.pay_time | parseTime()}}</div>
						</div>
						<div class="item" v-if="orderInfo.status > 20">
							<div class="item-label">{{$t('mobile.order.deliveryTime')}}</div>
							<div class="item-block">{{orderInfo.ship_time | parseTime()}}</div>
						</div>
						<div class="item" v-if="orderInfo.status === 40">
							<div class="item-label">{{$t('mobile.order.tradingTime')}}</div>
							<div class="item-block">{{orderInfo.finished_time | parseTime()}}</div>
						</div>
					</div>
				</div>
				<div class="morder-block"></div>
				<div class="morder-footer" v-if="orderInfo.status !== 20">
					<template v-if="orderInfo.status === 11">
						<div class="btn" @click="handleCancel">{{$t('mobile.actions.cancelOrder')}}</div>
						<div class="btn btn-red" @click="handlePayment">{{$t('mobile.actions.immedPay')}}</div>
					</template>
					<template v-if="orderInfo.status > 20">
						<div v-if="orderInfo.delivery_info.length > 1" class="btn" @click="changeLogistics">{{$t('mobile.actions.viewLogistics')}}</div>
						<router-link v-else class="btn" :to="{ name: 'mOrderLogistic',params: {id: orderInfo.order_id,code:orderInfo.delivery_info[0]?orderInfo.delivery_info[0].delivery_dist_id:null}}">{{$t('mobile.actions.viewLogistics')}}</router-link>
					</template>
					<div class="btn btn-red" v-if="orderInfo.status === 30" @click="handleReceipt">{{$t('mobile.actions.confirmReceipt')}}</div>
					<router-link class="btn btn-red" v-if="orderInfo.status === 40 && orderInfo.evaluation_status !== 1" :to="{ name: 'mOrderEvaluate', params: { id: orderInfo.order_id }}">{{$t('mobile.actions.immedEvaluation')}}</router-link>
					<div class="btn" v-if="orderInfo.status === 0" @click="handleDelete">{{$t('mobile.actions.deleteOrder')}}</div>
				</div>
			</div>
			<!-- 弹窗-支付方式 start -->
			<div class="mpop" :class="{'mpop-show':isPopShow.payment}">
				<div class="mpop-bg" @click="handlePopClose('payment')"></div>
				<div class="mpop-body">
					<div class="mpop-close" @click="handlePopClose('payment')"><i class="el-icon-close"></i></div>
					<div class="mpop-head">{{$t('mobile.order.payType')}}</div>
					<div class="mpop-group">
						<el-radio-group v-model="popPaymentCheck">
							<el-radio v-for="(item,index) in popPayment" :key="index" :label="JSON.stringify(item)">{{item.payment_name}}</el-radio>
						</el-radio-group>
					</div>
					<div class="mpop-bottom">
						<div class="btn" @click="handleOrderPayment">{{$t('mobile.actions.confirm')}}</div>
					</div>
				</div>
			</div>
			
			<!-- <div class="mpop" :class="{'mpop-show':isPopShow.alipay}">
				<div class="mpop-bg"></div>
				<div class="mpop-body">
					<div class="mpop-close left-close" @click="handleAlipayClose"><i class="el-icon-close"></i></div>
					<div class="mpop-head">{{$t('mobile.order.fictitious')}}</div>
					<div class="mpop-alipay">
						<div class="mpop-price">￥<span>{{ orderInfo?orderInfo.order_amount:'0.00' }}</span></div>
						<ul class="mpop-paytype">
							<li>
								<div class="item-icon"><svg-icon icon-class="money_bag_fill"></svg-icon></div>
								<div class="item-block">
									<div class="name">{{ $t('mobile.order.alipayText') }}</div>
									<div class="text">{{ $t('mobile.order.alipayDesc') }}</div>
								</div>
								<div class="item-next"><i class="el-icon-arrow-right"></i></div>
							</li>
						</ul>
					</div>
					<div class="mpop-bottom">
						<div class="btn blue-btn" @click="handleOrderAlipay">{{ $t('mobile.actions.confirmTrade') }}</div>
					</div>
				</div>
			</div>
			<div class="mpop" :class="{'mpop-show':isPopShow.cipher}">
				<div class="mpop-bg"></div>
				<div class="mpop-cipher">
					<div class="mpop-close left-close" @click="handlePopClose('cipher')"><i class="el-icon-close"></i></div>
					<div class="cipher-head">{{ $t('mobile.order.alipayTip') }}</div>
					<div class="cipher-main">
						<div class="cipher-input">
							<el-input v-model="payCipher" maxlength="6" type="tel" ref="paycipher" @input="changeCipher"></el-input>
						</div>
						<div class="cipher-box" @click="handleInput">
							<div class="item" :class="{ active: payCipher.length > 0 }"></div>
							<div class="item" :class="{ active: payCipher.length > 1 }"></div>
							<div class="item" :class="{ active: payCipher.length > 2 }"></div>
							<div class="item" :class="{ active: payCipher.length > 3 }"></div>
							<div class="item" :class="{ active: payCipher.length > 4 }"></div>
							<div class="item" :class="{ active: payCipher.length > 5 }"></div>
						</div>
					</div>
				</div>
			</div> -->
			<!-- 弹窗-支付方式 end -->
			
			<!-- 弹窗-物流 start -->
			<div class="mpop" :class="{'mpop-show':isPopShow.logistic}">
				<div class="mpop-bg"></div>
				<div class="mpop-body">
					<div class="mpop-close" @click="handlePopClose('logistic')"><i class="el-icon-close"></i></div>
					<div class="mpop-head">{{ $t('mobile.order.logisticPop') }}</div>
					<div class="mpop-group">
						<el-radio-group v-model="popLogisticCheck">
							<el-radio v-for="(item,index) in popLogistic" :key="index" :label="JSON.stringify(item)">{{item.delivery_name?item.logistic_code+'【'+item.delivery_name+'】':item.logistic_code}}</el-radio>
						</el-radio-group>
					</div>
					<div class="mpop-bottom">
						<div class="btn" @click="handleLogistic">{{ $t('mobile.actions.confirm') }}</div>
					</div>
				</div>
			</div>
			<!-- 弹窗-物流 end -->
		</div>
	</div>
</template>

<script>
	import { fetchOrder, cancelOrder, deleteOrder, confirmReceipt } from '@/api/mobile/order'
	
	export default {
		filters: {
			statusFilter(status, option) {
				return option[status]
			}
		},
		data() {
			return {
				orderInfo: {},
				addressInfo: null,
				isPopShow: {
					payment: false,
					alipay: false,
					cipher: false,
					logistic: false
				},
				popPayment: [],
				popPaymentCheck: '',
				payCipher: '',
				popLogistic: [],
				popLogisticCheck: ''
			}
		},
		created() {
			this.getOrderInfo()
		},
		methods: {
			getOrderInfo() {
				fetchOrder({ order_id: this.$route.params.id }).then(response => {
					const { data } = response
					this.orderInfo = data
					this.addressInfo = data.order_extm
					this.popLogistic = data.delivery_info
					this.getPaymentType(data.payments)
				}).catch(() => {
					this.$router.push({ name: 'mOrderList' })
				})
			},
			getPaymentType(data) {
				this.popPayment = data
				this.popPayment.forEach(item => {
					if(item.payment_code === 'alipay') {
						this.popPaymentCheck = JSON.stringify(item)
					}
				});
				if(!this.popPaymentCheck) {
					this.popPaymentCheck = JSON.stringify(this.popPayment[0])
				}
			},
			handlePayment() {
				this.isPopShow.payment = true;
			},
			handlePopClose(type){
				this.isPopShow[type] = false;
			},
			async handleOrderPayment() {
				this.isPopShow.payment = false;
				const paymentOption = JSON.parse(this.popPaymentCheck)
				const { data } = await this.$store.dispatch('payment/alipay', {order_sn: this.orderInfo.order_sn,payment_code: paymentOption.payment_code })
				if(paymentOption.payment_code === 'alipay') { // 支付宝支付
					const div = document.createElement('div')
					div.innerHTML = data.data
					document.body.appendChild(div)
					document.forms['alipaysubmit'].submit()
					document.body.removeChild(div)
				} else {
					this.$message({
						message: this.$t('mobile.successMsg.payment'),
						customClass: 'wap-message',
						type: 'success'
					});
					this.$router.push({name: "mOrderMsg",params:{ id: this.orderInfo.order_id }});
				}
			},
			// handleAlipayClose() {
			// 	this.$confirm(this.$t('mobile.confirm.waiverPay'),'',{
			// 		confirmButtonText: this.$t('mobile.actions.continuePay'),
			// 		cancelButtonText: this.$t('mobile.actions.waiver'),
			// 		customClass: 'wap-message-confirm',
			// 		showClose: false,
			// 		closeOnClickModal: false
			// 	}).then(() => { }).catch(() => {
			// 		this.isPopShow.alipay = false;
			// 	})
			// },
			// handleOrderAlipay() {
			// 	this.isPopShow.cipher = true;
			// 	this.$nextTick(() => {
			// 		this.$refs.paycipher.focus()
			// 	})
			// },
			// changeCipher(value) {
			// 	if (value.length > 5) {
			// 		paymentOrder({order_sn: this.orderInfo.order_sn}).then(() => {
			// 			this.$message({
			// 				message: this.$t('mobile.successMsg.payment'),
			// 				customClass: 'wap-message',
			// 				type: 'success'
			// 			});
			// 			this.$router.push({name: "mOrderMsg"});
			// 		})
			// 	}
			// },
			// handleInput() {
			// 	this.$refs.paycipher.focus()
			// },
			handleCancel() {
				this.$confirm(this.$t('mobile.confirm.cancelOrder'),'',{
					confirmButtonText: this.$t('mobile.actions.confirm'),
					cancelButtonText: this.$t('mobile.actions.cancel'),
					customClass: 'wap-message-confirm',
					showClose: false,
					closeOnClickModal: false
				}).then(() => {
					cancelOrder({ order_sn: this.orderInfo.order_sn }).then(() => {
						this.getOrderInfo()
					})
				}).catch(() => { })
			},
			handleDelete() {
				this.$confirm(this.$t('mobile.confirm.deleteOrder'),'',{
					confirmButtonText: this.$t('mobile.actions.confirm'),
					cancelButtonText: this.$t('mobile.actions.cancel'),
					customClass: 'wap-message-confirm',
					showClose: false,
					closeOnClickModal: false
				}).then(() => {
					deleteOrder({ order_sn: this.orderInfo.order_sn }).then(() => {
						this.getOrderInfo()
					})
				}).catch(() => { })
			},
			handleReceipt() {
				this.$confirm(this.$t('mobile.confirm.receiptText'),'',{
					confirmButtonText: this.$t('mobile.actions.confirm'),
					cancelButtonText: this.$t('mobile.actions.cancel'),
					customClass: 'wap-message-confirm',
					showClose: false,
					closeOnClickModal: false
				}).then(() => {
					confirmReceipt({ order_sn: this.orderInfo.order_sn }).then(() => {
						this.getOrderInfo()
					})
				}).catch(() => { })
			},
			changeLogistics() {
				this.isPopShow.logistic = true
			},
			handleLogistic() {
				if(this.popLogisticCheck) {
					const checkData = JSON.parse(this.popLogisticCheck)
					this.$router.push({ name: 'mOrderLogistic',params: {id: checkData.order_id,code:checkData.delivery_dist_id}})
				} else {
					this.$message({
						message: this.$t('mobile.errorMgs.selectLogistic'),
						customClass: 'wap-message',
						type: 'error'
					});
				}
			}
		},
	}
</script>

<style lang="scss" scoped>
	.mhome-order {
		padding: 0.384rem;
		.morder-status {
			padding: 0.384rem;
			margin-bottom: 0.256rem;
			border-radius: 0.256rem;
			background-color: #ffffff;
			.status-main {
				font-size: 0.448rem;
				font-weight: 600;
				line-height: 0.64rem;
				display: flex;
				align-items: center;
				.icon {
					font-size: 0.512rem;
					margin-right: 0.128rem;
					&.el-icon-circle-close {
						color: #ff3000;
					}
					&.el-icon-circle-check {
						color: #1dc11d;
					}
				}
			}
			.status-tip {
				color: #666;
				line-height: 0.544rem;
				margin-top: 0.128rem;
				font-size: 0.384rem;
			}
		}
		.morder-main {
			border-radius: 0.256rem;
			background-color: #ffffff;
			.morder-head {
				display: flex;
				padding: 0.384rem;
				line-height: 0.544rem;
				justify-content: space-between;
				.name {
					color: #333333;
					font-size: 0.448rem;
					font-weight: bold;
					i {
						color: #999999;
					}
				}
				.type {
					color: #999999;
					font-size: 0.416rem;
				}
			}
			.morder-goods {
				padding: 0 0.384rem;
				.item {
					margin-bottom: 0.256rem;
					&:last-child {
						margin-bottom: 0;
					}
				}
				.cell {
					display: flex;
					.cell-pic {
						flex-shrink: 0;
						width: 2.56rem;
						height: 2.56rem;
						border-radius: 0.32rem;
						object-fit: cover;
					}
					.cell-main {
						flex: 1;
						width: 0;
						margin: 0 0.32rem;
						.name {
							color: #333333;
							font-size: 0.416rem;
							height: 0.768rem;
							line-height: 0.768rem;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							font-weight: 600;
						}
						.desc {
							max-height: 1.088rem;
							line-height: 0.544rem;
							color: #999999;
							font-size: 0.416rem;
							display: -webkit-box;
							overflow: hidden;
							text-overflow: ellipsis;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							white-space: normal;
						}
					}
					.cell-box {
						flex-shrink: 0;
						text-align: right;
						.price {
							height: 0.768rem;
							line-height: 0.768rem;
							color: #333333;
							font-weight: 600;
							font-size: 0.352rem;
							span {
								font-size: 0.448rem;
							}
						}
						.number {
							height: 0.544rem;
							line-height: 0.544rem;
							color: #999999;
							font-size: 0.384rem;
						}
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
				.actions {
					display: flex;
					justify-content: flex-end;
					margin-top: 0.256rem;
					.btn {
						color: #333333;
						font-size: 0.416rem;
						flex-shrink: 0;
						min-width: 1.92rem;
						height: 0.544rem;
						line-height: 0.544rem;
						border: 1px solid #ddd;
						padding: 0.192rem 0.32rem;
						border-radius: 0.64rem;
						margin-left: 0.32rem;
						text-align: center;
					}
					.btn-red {
						color: #ff5000;
						border-color: #ff5000;
					}
				}
			}
			.morder-info {
				padding: 0.256rem 0.384rem;
				.item {
					display: flex;
					padding: 0.192rem 0;
					justify-content: space-between;
					.item-label {
						font-size: 0.416rem;
					}
					.item-block {
						max-width: 70%;
						color: #999999;
						font-size: 0.416rem;
						line-height: 0.576rem;
						text-align: right;
						.price {
							color: #333333;
							font-size: 0.384rem;
							margin-left: 0.32rem;
							font-weight: 600;
							em {
								font-size: 0.448rem;
							}
							&.red {
								color: #ff5000;
							}
							&.total {
								color: #ff5000;
								font-size: 0.416rem;
								em {
									font-size: 0.48rem;
								}
							}
						}
					}
				}
				.line {
					border-top: 1px solid #e5e5e5;
					margin: 0.128rem 0;
				}
			}
		}
	}
	.morder-block {
		height: 1.536rem;
	}
	.morder-footer {
		left: 0;
		bottom: 0;
		width: 100%;
		z-index: 9;
		box-sizing: border-box;
		background-color: #ffffff;
		padding: 0.256rem 0.384rem;
		position: absolute;
		display: flex;
		justify-content: flex-end;
		box-shadow: 0 0 2px rgba(0,0,0,.2);
		.btn {
			color: #333333;
			font-size: 0.416rem;
			flex-shrink: 0;
			height: 0.544rem;
			line-height: 0.544rem;
			border: 1px solid #ddd;
			padding: 0.192rem 0.32rem;
			border-radius: 0.64rem;
			margin-left: 0.32rem;
		}
		.btn-red {
			color: #ff5000;
			border-color: #ff5000;
		}
	}
	.mpop-group {
		padding: 0.256rem 0.384rem;
		::v-deep .el-radio-group {
			display: block;
			.el-radio {
				display: block;
				margin-right: 0;
				padding: 8px 0;
				.el-radio__inner {
					width: 0.512rem;
					height: 0.512rem;
				}
				.el-radio__inner:after {
					width: 0.192rem;
					height: 0.192rem;
				}
				.el-radio__label {
					color: #333333;
					font-size: 0.416rem;
					span {
						color: #999999;
						margin-left: 0.192rem;
						font-size: 0.384rem;
					}
				}
			}
		}
	}
	.mpop-alipay {
		height: 8rem;
		.mpop-price {
			text-align: center;
			font-size: 0.576rem;
			padding: 0.32rem 0;
			margin-bottom: 0.32rem;
			span {
				font-size: 0.96rem;
			}
		}
		.mpop-paytype {
			margin: 0 0.32rem;
			border-top: 1px solid #E5E5E5;
			li {
				display: flex;
				padding: 0.256rem 0;
				justify-content: space-between;
				border-bottom: 1px solid #E5E5E5;
				.item-icon {
					color: #ff0005;
					font-size: 0.512rem;
				}
				.item-block {
					flex: 1;
					margin-left: 0.32rem;
					.name {
						font-size: 0.416rem;
						line-height: 0.544rem;
					}
					.text {
						color: #999999;
						font-size: 0.352rem;
						line-height: 0.544rem;
						margin-top: 0.128rem;
					}
				}
				.item-next {
					line-height: 0.544rem;
					color: #999999;
					font-size: 0.448rem;
				}
			}
		}
	}
	
	.mpop-bottom {
		.blue-btn {
			border-radius: 0.32rem;
			background: $--uucolor-primary;
		}
	}
	.mpop-cipher {
		top: 50%;
		left: 10%;
		width: 80%;
		padding: 0.64rem;
		box-sizing: border-box;
		border-radius: 0.32rem;
		background-color: #FFFFFF;
		position: absolute;
		transform: translateY(-50%);
		.cipher-head {
			font-size: 0.48rem;
			line-height: 0.64rem;
			font-weight: 600;
			text-align: center;
			padding-top: 0.96rem;
		}
		.cipher-main {
			padding: 0.64rem 0;
			.cipher-input {
				height: 0;
				overflow: hidden;
			}
			.cipher-box {
				display: flex;
				border-radius: 0.16rem;
				border: 1px solid #e5e5e5;
				.item {
					flex: 1;
					width: 0;
					height: 1.28rem;
					line-height: 1.28rem;
					font-size: 0.512rem;
					text-align: center;
					border-left: 1px solid #e5e5e5;
					&:first-child {
						border-left: none;
					}
					&.active {
						display: flex;
						justify-content: center;
						align-items: center;
						&:before {
							content: "";
							width: 0.32rem;
							height: 0.32rem;
							display: block;
							border-radius: 50%;
							background-color: #333333;
						}
					}
				}
			}
		}
	}
</style>